import { useRedirect } from "@hooks/useRedirect"
import { useUserStore } from "@hooks/useUserStore"
import { StorageKeys } from "@utils/user-config"

export default function IsBrazil({ children }: { children: React.ReactNode }) {
	const { handleRedirect } = useRedirect()
	const { userProfile } = useUserStore()

	if (
		userProfile?.countryCode === "BRA" &&
		!localStorage.getItem(StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY)
	) {
		handleRedirect({
			link: "espresso",
			type: "internal"
		})
		return null
	}

	return children
}
