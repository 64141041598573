import SunnyCloudyIcon from "@assets/svg/navigation/weather.svg"
import cloudCoverIcon from "@assets/svg/weather/cloudCover.svg"
import RainIcon from "@assets/svg/weather/rain.svg"
import snowIcon from "@assets/svg/weather/snow.svg"
import sunIcon from "@assets/svg/weather/sun.svg"
import {
	Autocomplete,
	Box,
	Button,
	Divider,
	Flex,
	Group,
	Modal,
	Paper,
	Stack,
	Text,
	UnstyledButton
} from "@mantine/core"
import { useDebouncedValue, useDisclosure } from "@mantine/hooks"
import { changeLocationData } from "@services/authService"
import {
	forecastValues,
	getForecast,
	getLocation,
	getLocationData,
	getWeather,
	locationValue
} from "@services/weatherService"
import { IconCaretRightFilled } from "@tabler/icons-react"
import { WEATHER_DETAILS } from "@utils/constants.util"
import { matchSorter } from "match-sorter"
import { ReactElement, useEffect, useRef, useState } from "react"
import SevenDayForecast from "./SevenDayForecast"
import styles from "./WeatherWidget.module.css"
import { useAuthContext } from "../../../context/AuthProvider"
import { AEMSvg } from "@components/AEMSvg"

export interface WeatherObject {
	icon: ReactElement | string
	description: string
	temperature: string
	wind: string
}

export const getWeatherIcon = (weatherCode: number) => {
	const weatherIcons: Record<number, string> = {
		113: sunIcon,
		116: cloudCoverIcon,
		119: cloudCoverIcon,
		143: RainIcon,
		176: RainIcon,
		185: RainIcon,
		200: RainIcon,
		230: snowIcon,
		248: cloudCoverIcon,
		260: cloudCoverIcon,
		263: RainIcon,
		266: RainIcon,
		281: RainIcon,
		284: RainIcon,
		293: RainIcon,
		296: RainIcon,
		299: RainIcon,
		302: RainIcon,
		305: RainIcon,
		308: RainIcon,
		311: RainIcon
	}
	return weatherIcons[weatherCode] || SunnyCloudyIcon
}

export function WeatherWidget() {
	const { userProfile } = useAuthContext()
	const [location, setLocation] = useState("-")
	const [weather, setWeather] = useState<WeatherObject>({
		icon: WEATHER_DETAILS.icon,
		description: WEATHER_DETAILS.description,
		temperature: WEATHER_DETAILS.temperature,
		wind: WEATHER_DETAILS.wind
	})
	const [locationOptions, setLocationOptions] = useState<string[]>([])
	const [temperature, setTemperature] = useState<string>("-")
	const [unit, setUnit] = useState("")
	const [forecastData, setForecastData] = useState<forecastValues[]>([])
	const [opened, { open, close }] = useDisclosure()
	const [locationData, setLocationData] = useState<locationValue | null>(null)
	const [wind, setWind] = useState("-")
	const [humidity, setHumidity] = useState("-")
	const [precipitation, setPrecipitation] = useState(0)
	const [forecastIconCode, setforecastIconCode] = useState<string>("")
	const [searchQuery, setSearchQuery] = useState("")
	const [latlong, setLatLong] = useState("")
	const inputRef = useRef<HTMLInputElement | null>(null)
	const [uniqueLocations, setUniqueLocations] = useState<getLocationData[]>([])
	const [debouncedSearchTerm] = useDebouncedValue(searchQuery, 300)

	useEffect(() => {
		defaultLocation()
	}, [])

	useEffect(() => {
		if (latlong) {
			fetchWeather(latlong, unit)
		}
	}, [latlong, unit])

	useEffect(() => {
		locationSearch(debouncedSearchTerm)
	}, [debouncedSearchTerm])

	const fetchWeather = async (latLongs: string, unit: string) => {
		if (!latLongs || latLongs === "," || !unit) return

		const weatherData = await getWeather(latLongs, unit)
		const forecastValues = await getForecast(latLongs, unit)

		const forecastDataIcon = forecastValues?.forecast.map(
			(forecastItem: forecastValues) => {
				if (forecastItem?.hourly.length > 0) {
					return forecastItem?.hourly[0]?.weatherCode
				}
				return undefined
			}
		)

		setForecastData(forecastValues?.forecast || [])

		const weatherIcon = getWeatherIcon(weatherData?.current?.weatherCode)
		const forecastIcon = getWeatherIcon(forecastDataIcon[0] ?? 0)

		setforecastIconCode(forecastIcon)

		const temperature = weatherData?.current?.temperature
			? unit === "m"
				? `${weatherData?.current?.temperature}°C`
				: `${weatherData?.current?.temperature}°F`
			: "-"

		setTemperature(temperature)

		const windSpeed =
			unit === "m"
				? `${weatherData?.current?.windSpeed} m/s`
				: `${Math.round(weatherData?.current?.windSpeed)} mph`

		const wind = `Wind: ${weatherData?.current?.windDir ?? "-"} ${windSpeed} / Hum: ${weatherData?.current?.humidity ?? "-"}%`
		weatherData?.location?.name &&
		weatherData?.location?.region &&
		weatherData?.location?.country
			? `${weatherData?.location?.name}, ${weatherData?.location?.region}, ${weatherData?.location?.country}`
			: "Unknown Location"

		const locationName =
			weatherData?.location?.name &&
			weatherData?.location?.region &&
			weatherData?.location?.country
				? `${weatherData?.location?.name}, ${weatherData?.location?.region}, ${weatherData?.location?.country}`
				: "Unknown Location"

		const newWeatherData = {
			icon: weatherIcon,
			description: weatherData?.current?.weatherDescriptions?.[0] ?? " ",
			temperature: temperature,
			wind: wind
		}
		const windData = `${weatherData?.current?.windDir} ${windSpeed}`
		setWind(windData)

		const humidityData = `${weatherData?.current?.humidity}%`
		setHumidity(humidityData)

		const precipitationData = weatherData?.current?.precip
		setPrecipitation(precipitationData)

		setWeather({
			icon: newWeatherData.icon,
			description: newWeatherData.description,
			temperature: newWeatherData.temperature,
			wind: newWeatherData.wind
		})

		setLocation(locationName)
		setLocationData(weatherData?.location)
	}

	const defaultLocation = async () => {
		try {
			if (userProfile) {
				const countryCode = userProfile?.countryCode

				countryCode === "USA" ? setUnit("f") : setUnit("m")

				const latLongs = `${userProfile?.lat},${userProfile?.long}`
				setLatLong(latLongs)
				fetchWeather(latLongs, unit)
			}
		} catch (_error) {
			throw new Error("Error fetching weather data.")
		}
	}

	const handleLocationSearch = async (value: string) => {
		setSearchQuery(value)
	}

	const locationSearch = async (value: string) => {
		if (!value.trim() || value.trim().length < 3) {
			setLocationOptions([])
			return
		}

		const changeLocation = await getLocation(value)

		if (!changeLocation || changeLocation.length === 0) {
			setLocationOptions(["No results found"])
		} else {
			// Create an array of location objects
			const allLocations = changeLocation.map((loc) => {
				const lat = loc?.lat
				const long = loc?.lon
				return {
					name: loc?.name,
					region: loc?.region,
					country: loc?.country,
					lat: lat,
					lon: long,
					fullName: `${loc?.name}, ${loc?.region}, ${loc?.country}`
				}
			})

			// Now we can find unique locations based on fullName
			const uniqueLocations = Array.from(
				new Set(allLocations.map((loc) => loc.fullName))
			)
				.map((fullName) => {
					return allLocations.find((loc) => loc.fullName === fullName)
				})
				.filter((loc): loc is getLocationData => loc !== undefined)

			setUniqueLocations(uniqueLocations)

			const filteredLocations = matchSorter(
				uniqueLocations.map((loc) => loc.fullName),
				value,
				{
					threshold: matchSorter.rankings.CONTAINS
				}
			)
			setLocationOptions(filteredLocations)
		}
	}
	const handleLocationChange = async (selectedLocation: string) => {
		const location = locationOptions.find(
			(option) => option === selectedLocation
		)
		if (location) {
			// Extract latitude and longitude from selected location
			const loc = uniqueLocations.find((loc) => loc?.fullName === location)
			if (loc) {
				const latlong = `${loc?.lat},${loc?.lon}`
				setLatLong(latlong)

				fetchWeather(latlong, unit)
				changeLocationData(userProfile, loc?.lon)
			}
		}
		handleWidgetToggle("", "Change Location")
	}

	const highlightText = (text: string, query: string) => {
		if (!text || typeof text !== "string" || !query) return text

		const regex = new RegExp(`(${query})`, "gi")
		const parts = text.split(regex)

		return parts.map((part, index) => {
			const isMatch = regex.test(part)
			return isMatch ? (
				<span key={index} className={styles.highlightText}>
					{part}
				</span>
			) : (
				part
			)
		})
	}

	const handleKeyDown = (location: string) => {
		if (location) {
			const selectedLocationObj = uniqueLocations.find(
				(loc) => loc?.fullName.toLowerCase() === location.toLowerCase()
			)

			if (selectedLocationObj) {
				const latlong = `${selectedLocationObj.lat},${selectedLocationObj.lon}`
				setLatLong(latlong)
				fetchWeather(latlong, unit)
			}
			handleWidgetToggle("", "Change Location")
		}
	}

	const handleFocus = () => {
		setSearchQuery("")
		setLocationOptions([])
	}

	const toggleUnit = (newUnit?: string) => {
		const finalUnit = newUnit ?? (unit === "m" ? "f" : "m")
		setUnit(finalUnit)

		if (latlong) {
			fetchWeather(latlong, finalUnit)
		} else {
			defaultLocation()
		}
	}

	const handleWidgetToggle = (action: "open" | "close" | "", title: string) => {
		action === "open" ? open() : close()

		const payload = {
			event_name: "navigation_click",
			nav_link_text: title || "",
			interaction_type: action === "open" ? "Widget open" : "Widget close",
			category: "Header Menu click",
			link_url: "",
			page_url: window.location.href,
			event211: 1,
			page_name: document.title || "",
			page_section: "",
			site_language: navigator.language || "en"
		}
		window.utag.link(payload)
	}
	return (
		<>
			<Paper className={styles.weatherWidget} shadow="lg" p={30} radius="md">
				<Stack gap="lg">
					<Box>
						<Text
							fz={13}
							fw={500}
							lh={1.6}
							className={styles.weatherWidgetText}
						>
							Your Location
						</Text>
						<Text
							fz={18}
							fw={600}
							lh={1.2}
							className={styles.weatherWidgetText}
						>
							{location}
						</Text>
					</Box>
					<Group>
						<AEMSvg
							svgData={{
								path: weather.icon as string
							}}
						/>
						<Box>
							<Text
								fz={13}
								fw={500}
								lh={1.6}
								className={styles.weatherWidgetText}
							>
								{weather.description}
							</Text>
							<Text
								fz={21}
								fw={500}
								lh={1}
								className={styles.weatherWidgetText}
							>
								{temperature}
							</Text>
							<Text fz={11} fw={500} className={styles.weatherWidgetText}>
								{weather.wind}
							</Text>
						</Box>
					</Group>
					<Group>
						<UnstyledButton
							fz={12}
							fw={600}
							lh={1.7}
							className={styles.weatherWidgetText}
							td="underline"
							tt="uppercase"
							onClick={() => {
								handleWidgetToggle("open", "7-Day Forecast")
								open()
							}}
						>
							7-Day Forecast
						</UnstyledButton>
					</Group>
					<Divider />
					<Flex gap="md" wrap="wrap">
						<Box className={styles.autoCompleteFlexWrapper}>
							<Autocomplete
								label="Change Location"
								placeholder="City, State/Province, Country"
								comboboxProps={{ withinPortal: false }}
								data={locationOptions}
								value={searchQuery}
								onChange={handleLocationSearch}
								variant="small-input"
								renderOption={(input) => {
									const { value } = input.option
									return (
										<div
											{...input}
											key={value}
											onClick={() => handleLocationChange(value)}
										>
											{highlightText(value || "", searchQuery)}
										</div>
									)
								}}
								classNames={{
									root: styles.autoCompleteFlexWrapper,
									label: styles.autoCompleteLabel,
									dropdown: styles.mantineAutocompleteDropdown,
									option: styles.autocompleteOption
								}}
								onFocus={handleFocus}
								onOptionSubmit={handleKeyDown}
								ref={inputRef}
							/>
						</Box>
						<Group mt="md" className={styles.h60}>
							<Button
								variant="filled"
								leftSection={<IconCaretRightFilled size={20} />}
								classNames={{
									root: styles.autocompleteButton,
									inner: styles.autocompleteInner,
									section: styles.mantineButtonSection
								}}
								onClick={() => handleLocationChange}
							/>
						</Group>
					</Flex>
				</Stack>
			</Paper>
			<Modal
				opened={opened}
				onClose={() => handleWidgetToggle("close", "7-Day Forrecast")}
				title={
					<Text fw={600} size="xl">
						7-Day FORECAST
					</Text>
				}
				size="xl"
				centered
				classNames={{
					body: styles.modalBody,
					title: styles.modalTitle
				}}
			>
				<SevenDayForecast
					forecast={forecastData}
					locationData={locationData}
					WindValues={wind}
					HumidityValue={humidity}
					precipitationValue={precipitation}
					weatherData={weather}
					forecastIcon={forecastIconCode}
					unit={unit}
					toggleUnit={toggleUnit}
				/>
			</Modal>
		</>
	)
}
