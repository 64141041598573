import { type CSSVariablesResolver } from "@mantine/core"

/**
 * Sets or overrides Mantine CSS variables based on theme values.
 * See https://mantine.dev/styles/css-variables/#css-variables-resolver for details.
 * @param theme
 */
export const cssVariableResolver: CSSVariablesResolver = (theme) => ({
	variables: {},
	light: {
		// Design System Colors
		"--primary-color": theme.colors.royal[0],
		"--secondary-color": theme.colors.royal[1],
		"--brand-color-royal": theme.colors.royal[2],
		"--brand-color-celebrity": theme.colors.royal[3],
		"--brand-color-silversea": theme.colors.royal[4],
		"--text-color-primary": theme.colors.royal[5],
		"--text-color-secondary": theme.colors.royal[6],
		"--text-color-ternary": theme.colors.royal[5],
		"--text-color-headline-gradient": theme.other.gradients.headline,
		"--text-color-white": theme.colors.royal[6],
		"--text-color-icon": theme.colors.royal[8],
		"--text-color-grey": theme.colors.royal[21],
		"--text-color-alternative": theme.colors.royal[5],
		"--text-color-white-primary": theme.colors.royal[6],
		"--info-card-border-color-icon": theme.colors.royal[29],
		"--navigation-color-primary": theme.colors.royal[6],
		"--navigation-color-secondary": theme.colors.royal[7],
		"--navigation-color-ternary": theme.colors.royal[8],
		"--background-color-primary": theme.colors.royal[10],
		"--background-color-secondary": theme.colors.royal[1],
		"--background-color-ternary": theme.colors.royal[10],
		"--background-color-card": theme.colors.royal[6],
		"--background-color-login": theme.colors.royal[10],
		"--background-color-gradient": theme.other.gradients.background,
		"--background-gradient-title": theme.other.gradients["title-background"],
		"--icon-color": theme.colors.royal[5],
		"--button-color-primary": theme.colors.royal[5],
		"--button-color-border": theme.colors.royal[24],
		"--table-header-color": theme.colors.royal[5],
		"--table-row-primary-color": theme.colors.royal[6],
		"--table-row-secondary-color": theme.colors.royal[25],
		"--focus-ring-color-outer": theme.colors.royal[5],
		"--focus-ring-color-inner": theme.colors.royal[0],
		// Design System Colors

		// Old colors
		"--mantine-color-body": theme.colors.royal[10],
		"--mantine-color-text": theme.colors.royal[5],
		"--mantine-primary-color-contrast": theme.colors.royal[0],
		"--nav-bg-color": theme.white,
		"--icon-hover-color": theme.colors.royal[19],
		"--dropdown-bg": theme.white
	},
	dark: {
		// Design System Colors
		"--primary-color": theme.colors.royal[0],
		"--secondary-color": theme.colors.royal[1],
		"--brand-color-royal": theme.colors.royal[2],
		"--brand-color-celebrity": theme.colors.royal[3],
		"--brand-color-silversea": theme.colors.royal[4],
		"--text-color-primary": theme.colors.royal[12],
		"--text-color-secondary": theme.colors.royal[5],
		"--text-color-ternary": theme.colors.royal[12],
		"--text-color-headline-gradient": theme.colors.royal[13],
		"--text-color-white": theme.colors.royal[6],
		"--text-color-grey": theme.colors.royal[12],
		"--text-color-icon": theme.colors.royal[14],
		"--text-color-alternative": theme.colors.royal[6],
		"--text-color-white-primary": theme.colors.royal[12],
		"--info-card-border-color-icon": theme.colors.royal[28],
		"--navigation-color-primary": theme.colors.royal[15],
		"--navigation-color-secondary": theme.colors.royal[7],
		"--navigation-color-ternary": theme.colors.royal[8],
		"--background-color-primary": theme.colors.royal[16],
		"--background-color-secondary": theme.colors.royal[17],
		"--background-color-ternary": theme.colors.royal[17],
		"--background-color-card": theme.colors.royal[17],
		"--background-color-login": theme.colors.royal[0],
		"--background-color-gradient": theme.other.gradients.background,
		"--background-gradient-title": theme.other.gradients["title-background"],
		"--icon-color": theme.colors.royal[18],
		"--button-color-primary": theme.colors.royal[6],
		"--button-color-border": theme.colors.royal[23],
		"--table-header-color": theme.colors.royal[15],
		"--table-row-primary-color": theme.colors.royal[17],
		"--table-row-secondary-color": theme.colors.royal[16],
		"--focus-ring-color-outer": theme.colors.royal[0],
		"--focus-ring-color-inner": theme.colors.royal[5],
		// Design System Colors

		// Old colors
		"--mantine-color-body": theme.colors.royal[16],
		"--mantine-color-text": theme.colors.royal[12],
		"--mantine-primary-color-contrast": theme.colors.royal[33],
		"--nav-bg-color": theme.colors.royal[28],
		"--icon-hover-color": theme.colors.royal[17],
		"--dropdown-bg": theme.colors.royal[17]
	}
})
