import { create } from "zustand"
import { UserProfile } from "../models/userProfile.model"

interface UserStore {
	userProfile: UserProfile | null
	setUserProfile: (profile: UserProfile | null) => void
}

export const useUserStore = create<UserStore>((set) => ({
	userProfile: null,
	setUserProfile: (profile) => set({ userProfile: profile })
}))
