import { StorageKeys } from "@utils/user-config"
import axios, { AxiosError } from "axios"
import { LoginError } from "../models/error/login-error"
import { UserProfile } from "../models/userProfile.model"
import { BASE_URL, VITE_AUTH_ENCRYPTION_KEY } from "../utils/constants.util"
import { useUserStore } from "@hooks/useUserStore"
import secureLocalStorage from "react-secure-storage"

export interface LoginResponse {
	access_token: string
}

export interface RemoteAccessResponse {
	remote_token: string
}

interface RemoteAccess {
	remoteAccessToken: string
}

export const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "application/json"
	}
})

api.interceptors.request.use(
	(config) => {
		const token = getAuthToken()
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		if (response.config.url !== "/auth/logout") {
			secureLocalStorage.setItem(
				StorageKeys.LAST_ACTIVE_TIME,
				Date.now().toString()
			)
		}
		return response
	},
	(error: AxiosError) => {
		console.error("API request error:", error.response?.data || error.message)
		return Promise.reject(error)
	}
)

export const getAuthToken = (): string => {
	const token = secureLocalStorage.getItem(StorageKeys.USER_TOKEN)
	return token as string
}

const setAuthToken = (token: string): void => {
	secureLocalStorage.setItem(StorageKeys.USER_TOKEN, token)
	secureLocalStorage.setItem(
		StorageKeys.USER_LOGIN_TIME,
		JSON.stringify(Date.now())
	)
}

const clearSession = (): void => {
	secureLocalStorage.removeItem(StorageKeys.USER_TOKEN)
	secureLocalStorage.removeItem(StorageKeys.USER_PROFILE)
	secureLocalStorage.removeItem(StorageKeys.USER_LOGIN_TIME)
	secureLocalStorage.removeItem(StorageKeys.LAST_ACTIVE_TIME)
	secureLocalStorage.removeItem(StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY)
	secureLocalStorage.removeItem(
		StorageKeys.LEGACY_AUTHOMATIC_REDIRECT_ALREADY_DONE
	)
}

const getLoginDate = (): string | null => {
	return secureLocalStorage.getItem(StorageKeys.USER_LOGIN_TIME) as string
}

const getTokenExpiration = (): string | null => {
	return new Date(Date.now() + 1 * 60 * 1000).toISOString()
}

export const login = async (
	username: string,
	password: string
): Promise<string> => {
	try {
		const encryptedCredentials = await encryptCredentials({
			username,
			password
		})
		const { data } = await api.post<{ data: LoginResponse }>("/auth/login", {
			encryptedCredentials
		})
		const token = data.data.access_token
		return token
		// biome-ignore lint/suspicious/noExplicitAny: We dont have currently an error interface for axios
	} catch (error: any) {
		const { message, errorCode, remainingAttempts } =
			error.response?.data?.message || {}

		if (!errorCode) {
			throw new LoginError(
				"Service unavailable, please try again later",
				errorCode,
				remainingAttempts
			)
		}

		throw new LoginError(message, errorCode, remainingAttempts)
	}
}

const encryptCredentials = async (data: {
	username: string
	password: string
}): Promise<string> => {
	const ENCRYPTION_KEY = VITE_AUTH_ENCRYPTION_KEY

	const jsonString = JSON.stringify(data)

	const encoder = new TextEncoder()
	const dataBuffer = encoder.encode(jsonString)

	const iv = window.crypto.getRandomValues(new Uint8Array(12))

	const key = await window.crypto.subtle.importKey(
		"raw",
		encoder.encode(ENCRYPTION_KEY),
		{ name: "AES-GCM" },
		false,
		["encrypt"]
	)

	const encryptedContent = await window.crypto.subtle.encrypt(
		{
			name: "AES-GCM",
			iv: iv
		},
		key,
		dataBuffer
	)

	const encryptedArray = new Uint8Array([
		...iv,
		...new Uint8Array(encryptedContent)
	])
	return btoa(String.fromCharCode(...encryptedArray))
}

export const logout = (): void => {
	try {
		api.delete<{ data: boolean }>("/auth/logout", {
			headers: { Authorization: `Bearer ${getAuthToken()}` }
		})
	} catch (_error) {
		throw new Error("Error logout user")
	}
}

export const checkSession = (): boolean => {
	return !!secureLocalStorage.getItem(StorageKeys.USER_TOKEN)
}

export const remoteCPAccess = async (
	target: string
): Promise<RemoteAccessResponse> => {
	try {
		const { data } = await api.get<{ data: RemoteAccessResponse }>(
			`/auth/remoteCPAccess?target=${target}`
		)
		return data.data
	} catch (_error) {
		throw new Error("Error accessing remotely")
	}
}

export const webserviceAccess = async (
	applicationId: string
): Promise<RemoteAccess> => {
	try {
		const { data } = await api.get<{ data: RemoteAccess }>("/redirect", {
			params: { applicationId }
		})
		return data.data
	} catch (_error) {
		throw new Error("Error accessing web service")
	}
}

export const fetchProfile = async (): Promise<UserProfile> => {
	try {
		const { data } = await api.get<{ data: UserProfile }>("/userProfile")

		return data.data
	} catch (_error) {
		throw new Error("Error fetching user profile")
	}
}

export const changeLocationData = (
	userProfile: UserProfile | null,
	lat?: string,
	long?: string
): void => {
	const { setUserProfile } = useUserStore()

	if (userProfile) {
		const locationLat =
			lat ??
			(userProfile.locationData?.find((item) => item.key === "lat")?.value ||
				"")
		const locationLong =
			long ??
			(userProfile.locationData?.find((item) => item.key === "long")?.value ||
				"")

		const updatedProfile = {
			...userProfile,
			lat: locationLat ?? "",
			long: locationLong ?? ""
		}

		setUserProfile(updatedProfile)
	}
}

export const moveLocationData = (profile: UserProfile) => {
	const locationLat =
		profile?.locationData?.find((item) => item.key === "lat")?.value || ""
	const locationLong =
		profile?.locationData?.find((item) => item.key === "long")?.value || ""
	return {
		...profile,
		lat: locationLat,
		long: locationLong
	}
}

interface ValidateLegacyTokenResponse {
	isValid: boolean
	payload: {
		"creation-date-time": string
		username: string
	}
	access_token: string
}

export const validateLegacyToken = async (
	token: string
): Promise<string | null> => {
	try {
		const { data } = await api.get<{
			data: ValidateLegacyTokenResponse
		}>("/auth/validate-legacy-token", {
			headers: {
				"Cp-Token": token
			}
		})

		if (data.data.isValid && data.data.access_token) {
			return data.data.access_token
		}

		return null
	} catch (error) {
		throw new Error(`Error validating legacy token ${error}`)
	}
}

export const AuthService = {
	login,
	logout,
	checkSession,
	remoteCPAccess,
	webserviceAccess,
	getAuthToken,
	setAuthToken,
	fetchProfile,
	clearSession,
	getLoginDate,
	getTokenExpiration,
	validateLegacyToken
}
