import axios, { AxiosResponse } from "axios"
import { VITE_AEM_API_KEY, VITE_AEM_URL } from "../utils/constants.util"
import { getAuthToken } from "./authService"

export interface locationValue {
	id: number
	name: string
	country: string
	region: string
	lat: string
	lon: string
	timezoneId: string
	localTime: string
	localTimeEpoch: number
	utcOffset: string
}

interface currentLocation {
	time: string
	temperature: number
	weatherCode: number
	weatherIcons: string[]
	weatherDescriptions: string[]
	windSpeed: number
	windDegree: number
	windDir: string
	pressure: number
	precip: number
	humidity: number
	cloudCover: number
	feelsLike: number
	uvIndex: number
	visibility: number
}

interface hourlyValues {
	time: string
	temperature: number
	windSpeed: number
	windDegree: number
	windDir: string
	weatherCode: number
	weatherIcons: string[]
	weatherDescriptions: string[]
	precip: number
	humidity: number
	visibility: number
	pressure: number
	cloudCover: number
	heatIndex: number
	dewpoint: number
	windChill: number
	windGust: number
	feelsLike: number
	chanceOfRain: number
	chanceOfRemDry: number
	chanceOfWindy: number
	chanceOfOvercast: number
	chanceOfSunshine: number
	chanceOfFrost: number
	chanceOfHighTemp: number
	chanceOfFog: number
	chanceOfSnow: number
	chanceOfThunder: number
	uvIndex: number
}

interface astroValues {
	sunrise: string
	sunset: string
	moonrise: string
	moonset: string
	moonIllumination: number
	moonPhase: string
}

export interface forecastValues {
	date: string
	dateEpoch: number
	minTemp: number
	maxTemp: number
	avgTemp: number
	totalSnow: number
	sunHour: number
	uvIndex: number
	hourly: hourlyValues[]
	astro: astroValues[]
}

interface WeatherData {
	location: locationValue
	current: currentLocation
	forecast: forecastValues[]
}

export interface getLocationData {
	fullName: string
	name: string
	country: string
	region: string
	lat: string
	lon: string
	timezoneId: string
	localTime: string
	localTimeEpoch: string
	utcOffset: string
}

export async function getWeather(
	value: string,
	units: string
): Promise<WeatherData> {
	try {
		const baseUrl = `${VITE_AEM_URL}/api/weather/live/current`
		const apikey = VITE_AEM_API_KEY
		const response: AxiosResponse<WeatherData[]> = await axios.get<
			WeatherData[]
		>(`${baseUrl}?location=${value}&units=${units}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getAuthToken()}`,
				Apikey: apikey,
				"edge-cache-tag": "weather-current"
			}
		})

		const members = response.data
		if (!members || members.length === 0) {
			throw new Error("Weather data not available for this location.")
		}

		const weatherData = members[0]

		const weatherResponse: WeatherData = {
			location: weatherData.location,
			current: weatherData.current,
			forecast: [
				{
					date: weatherData.forecast[0]?.date || "",
					dateEpoch: weatherData.forecast[0]?.dateEpoch || 0,
					minTemp: weatherData.forecast[0]?.minTemp || 0,
					maxTemp: weatherData.forecast[0]?.maxTemp || 0,
					avgTemp: weatherData.forecast[0]?.avgTemp || 0,
					totalSnow: weatherData.forecast[0]?.totalSnow || 0,
					sunHour: weatherData.forecast[0]?.sunHour || 0,
					uvIndex: weatherData.forecast[0]?.uvIndex || 0,
					hourly: weatherData.forecast[0]?.hourly || ({} as hourlyValues),
					astro: weatherData.forecast[0]?.astro || ({} as astroValues)
				}
			]
		}

		return weatherResponse
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.status === 404) {
				throw new Error("Weather conditions not found or invalid input.")
			}
			throw new Error("Error fetching weather data.")
		}
		throw error
	}
}

export async function getForecast(
	value: string,
	units: string
): Promise<WeatherData> {
	try {
		const baseUrl = `${VITE_AEM_URL}/api/weather/live/weekly`
		const apikey = VITE_AEM_API_KEY
		const response: AxiosResponse<WeatherData[]> = await axios.get<
			WeatherData[]
		>(`${baseUrl}?location=${value}&units=${units}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getAuthToken()}`,
				Apikey: apikey,
				"edge-cache-tag": "weather-weekly"
			}
		})

		const members = response.data
		if (!members || members.length === 0) {
			throw new Error("Weather data not available for this location.")
		}

		const weatherData = members[0]

		const weatherResponse: WeatherData = {
			location: weatherData.location,
			current: weatherData.current,
			forecast: weatherData.forecast
		}

		return weatherResponse
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.status === 404) {
				throw new Error("Weather conditions not found or invalid input.")
			}
			throw new Error("Error fetching weather data.")
		}
		throw error
	}
}

export async function getLocation(value: string): Promise<getLocationData[]> {
	try {
		const baseUrl = `${VITE_AEM_URL}/api/weather/locations`
		const apikey = VITE_AEM_API_KEY
		const response: AxiosResponse<getLocationData[]> = await axios.get<
			getLocationData[]
		>(`${baseUrl}?query=${value}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getAuthToken()}`,
				Apikey: apikey,
				"edge-cache-tag": "weather-locations"
			}
		})

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.status === 404) {
				throw new Error("location conditions not found or invalid input.")
			}
			throw new Error("Error fetching location data.")
		}
		throw error
	}
}
