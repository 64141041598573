import {
	useQuery,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
	UseQueryResult
} from "@tanstack/react-query"
import {
	VITE_AEM_API_KEY,
	VITE_AEM_CREDENTIALS,
	VITE_AEM_URL,
	VITE_AEM_USE_FALLBACK,
	VITE_AEM_USE_FALLBACK_WITH_EN
} from "../utils/constants.util"
import {
	AEMPage,
	AEMPageType,
	AEMComponentType,
	AEMComponentMapper,
	AEMNavigation,
	AEMAlert,
	AEMPagePaths
} from "./AEMPage.model"
import { useEffect, useState } from "react"
import {
	AppEvents,
	AvailableLanguages,
	QueryKeys,
	StorageKeys
} from "@utils/user-config"
import homeData from "./fallbackData/home.json"
import loginData from "./fallbackData/login.json"
import footerData from "./fallbackData/footer.json"
import seeOurShipsData from "./fallbackData/seeOurShips.json"
import { useAuthContext } from "../context/AuthProvider"
import { deepMerge } from "@utils/deepMerge"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"
const queryClient = new QueryClient()

const fallbackData: Partial<Record<string, unknown>> = {
	[AEMPageType.HOME]: homeData,
	[AEMPageType.LOGIN]: loginData,
	[AEMPageType.FOOTER]: footerData,
	[AEMPageType.SEE_OUR_SHIPS]: seeOurShipsData
}

const AVAILABLE_UNAUTHENTICATED_PAGES = [
	AEMPageType.LOGIN,
	AEMPageType.FOOTER,
	AEMPageType.DICTIONARY,
	AEMPageType.LANGUAGES,
	AEMPageType.NAVIGATION_FOOTER,
	AEMPageType.PAGES_TERMS_OF_USE,
	AEMPageType.PAGES_PRIVACY_POLICY
]

type AEMResponse = AEMPage | AEMNavigation | AEMAlert

const getAEMContent = async (
	page: AEMPageType,
	locale: string,
	role?: string,
	office?: string,
	country?: string
): Promise<AEMPage | AEMNavigation> => {
	// Fetch primary language content
	let data = await fetchAEMContent(page, locale, role, office, country)

	// If locale is not English, fetch English content as fallback
	if (locale !== AvailableLanguages.EN_US && VITE_AEM_USE_FALLBACK_WITH_EN) {
		const englishContent = await fetchAEMContent(
			page,
			AvailableLanguages.EN_US,
			role,
			office,
			country
		)
		// Deep merge the contents, with primary language taking precedence
		data = deepMerge(englishContent, data)
	}

	return data
}

const fetchAEMContent = async (
	page: AEMPageType,
	locale: string,
	role?: string,
	office?: string,
	country?: string
): Promise<AEMPage | AEMNavigation> => {
	try {
		const credentials = btoa(VITE_AEM_CREDENTIALS)
		const baseUrl = new URL(
			`${VITE_AEM_URL}/api/royal/content/cruising-power${AEMPagePaths[page]}`
		)

		const params = new URLSearchParams({
			locale: locale
		})

		if (office) params.append("office", office.toLowerCase())
		if (role) params.append("role", role.toLowerCase())
		if (country) params.append("country", country.toLowerCase())

		baseUrl.search = params.toString()

		const { data } = await axios.get<AEMResponse>(baseUrl.toString(), {
			headers: {
				Apikey: VITE_AEM_API_KEY,
				Authorization: `Basic ${credentials}`,
				"edge-cache-tag": `${page}-${locale}`
			},
			validateStatus: (status) => status === 200
		})

		if (page === AEMPageType.DICTIONARY) {
			return {
				items: [
					{ components: [{ type: AEMComponentMapper.APP_DICTIONARY, ...data }] }
				]
			}
		}
		if (page === AEMPageType.NAVIGATION_HOME && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.NAVIGATION_FOOTER && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.FOOTER && "items" in data) {
			if (!data.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: data.items.map((item) => ({
							type: AEMComponentMapper.APP_FOOTER,
							...item
						}))
					}
				]
			}
		}

		if (page === AEMPageType.NAVIGATION_GLOBAL && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.NAVIGATION_SIDEBAR && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.LANGUAGES && "items" in data) {
			if (!data.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{ type: AEMComponentMapper.APP_LANGUAGES, languages: data.items }
						]
					}
				]
			}
		}

		if (page === AEMPageType.ALERTS && "data" in data) {
			if (!data.data?.componentAlertBannerList?.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.APP_ALERTS,
								items: data?.data?.componentAlertBannerList?.items
							}
						]
					}
				]
			}
		}

		if (page === AEMPageType.NOTIFICATIONS && "data" in data) {
			if (!data.data?.componentNotificationsList?.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.APP_NOTIFICATIONS,
								items: data?.data?.componentNotificationsList?.items
							}
						]
					}
				]
			}
		}

		if (
			!("items" in data) ||
			!data.items ||
			!data.items[0] ||
			!data.items[0].components
		) {
			throw new Error("Invalid data structure: missing items[0].components")
		}

		return data
	} catch (error) {
		console.error(`Error fetching AEM content for ${page}:`, error)

		const fallbackPageData = fallbackData[page as AEMPageType]
		// Santiago Ponce: Aem fallback meant only for debugging pruposes, do not allow on production
		if (fallbackPageData && VITE_AEM_USE_FALLBACK) {
			console.warn(`Using fallback data for ${page}`)
			return fallbackPageData as AEMPage
		}

		throw error
	}
}
export const useAEMContent = (page: AEMPageType) => {
	const [locale, setLocale] = useState(
		secureLocalStorage.getItem(StorageKeys.USER_PREFERRED_LANGUAGE) ||
			AvailableLanguages.EN_US
	)
	const { userProfile } = useAuthContext()

	useEffect(() => {
		const handleLanguageChange = () => {
			setLocale(
				secureLocalStorage.getItem(StorageKeys.USER_PREFERRED_LANGUAGE) ||
					AvailableLanguages.EN_US
			)
		}
		window.addEventListener(
			AppEvents.USER_LANGUAGE_CHANGED,
			handleLanguageChange
		)
		return () =>
			window.removeEventListener(
				AppEvents.USER_LANGUAGE_CHANGED,
				handleLanguageChange
			)
	}, [])

	return useQuery({
		queryKey: [
			QueryKeys.AEM_CONTENT,
			page,
			locale,
			userProfile?.roleCode,
			userProfile?.reportingOfficeCode,
			userProfile?.countryCode
		],
		queryFn: () =>
			getAEMContent(
				page,
				locale as string,
				userProfile?.roleCode,
				userProfile?.reportingOfficeCode,
				userProfile?.countryCode
			),
		staleTime: 5 * 60 * 1000,
		gcTime: 10 * 60 * 1000,
		enabled: AVAILABLE_UNAUTHENTICATED_PAGES.includes(page) || !!userProfile
	})
}

export const useAEMComponents = (page: AEMPageType) => {
	const { data, isLoading, error } = useAEMContent(
		page
	) as UseQueryResult<AEMPage>

	const components = data?.items[0]?.components

	return { components, isLoading, error }
}

export const useAEMComponentData = (
	page: AEMPageType,
	componentType: string,
	uniqueId?: string
) => {
	const { data, isLoading, error } = useAEMContent(
		page
	) as UseQueryResult<AEMPage>
	const componentData = data?.items[0]?.components?.find(
		(component: AEMComponentType) =>
			component.type === componentType &&
			(uniqueId ? component.uniqueId === uniqueId : true)
	)

	return { componentData, isLoading, error }
}

export const useAEMNavData = (navType: AEMPageType) => {
	const { data, isLoading, error } = useAEMContent(
		navType
	) as UseQueryResult<AEMNavigation>
	return { data, isLoading, error }
}

export const AEMContentProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
export const useAEMContentRefresh = (pages: AEMPageType[]) => {
	const queryClient = useQueryClient()

	useEffect(() => {
		const refreshContent = (event: CustomEvent<{ locale: string }>) => {
			const locale = event.detail.locale
			for (const page of pages) {
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.AEM_CONTENT, page, locale]
				})
			}
		}

		window.addEventListener(
			AppEvents.USER_LANGUAGE_CHANGED,
			refreshContent as EventListener
		)
		return () =>
			window.removeEventListener(
				AppEvents.USER_LANGUAGE_CHANGED,
				refreshContent as EventListener
			)
	}, [pages, queryClient])
}
