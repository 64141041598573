export enum AvailableLanguages {
	EN_US = "en_us",
	FR_FR = "fr_fr",
	DE_DE = "de_de",
	IT_IT = "it_it",
	PT_BR = "es_xn",
	ZH_CN = "zh_cn",
	DEFAULT = "en_us"
}

export enum StorageKeys {
	USER_TOKEN = "jwt",
	USER_PREFERRED_LANGUAGE = "userPreferredLanguage",
	USER_PREFFERED_COLOR_SCHEME = "colorScheme",
	USER_PROFILE = "userprofile",
	USER_LOGIN_TIME = "loginTime",
	LAST_ACTIVE_TIME = "lastActiveTime",
	HAS_USER_NAVIGATED_TO_LEGACY = "hasUserNavigatedToLegacy",
	LEGACY_AUTHOMATIC_REDIRECT_ALREADY_DONE = "legacyAuthomaticRedirectAlreadyDone",
	LOCAL_LOCATION = "localLocation"
}

export enum AppEvents {
	USER_LANGUAGE_CHANGED = "userLanguageChanged"
}

export enum QueryKeys {
	AEM_CONTENT = "aemContent",
	PARTNER_INSIGHTS_OVERVIEW = "partnerInsightsOverview",
	PARTNER_INSIGHTS_ALERTS = "partnerInsightsOverview",
	PARTNER_INSIGHTS_OPEN_BOOKINGS = "partnerInsightsOpenBookings",
	PARTNER_INSIGHTS_CONFIRMED_BOOKINGS = "partnerInsightsConfirmedBookings",
	PARTNER_INSIGHTS_CASINO_RESERVATIONS = "partnerInsightsCasinoReservations"
}
