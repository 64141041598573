export const BASE_URL = import.meta.env.VITE_BASE_URL
export const VITE_AEM_URL = import.meta.env.VITE_AEM_URL
export const VITE_AEM_CREDENTIALS = import.meta.env.VITE_AEM_CREDENTIALS
export const VITE_AEM_API_KEY = import.meta.env.VITE_AEM_API_KEY
export const VITE_AEM_USE_FALLBACK = import.meta.env.VITE_AEM_USE_FALLBACK
export const VITE_USE_LANGUAGE_PICKER = import.meta.env.VITE_USE_LANGUAGE_PICKER
export const VITE_USE_DARK_MODE = import.meta.env.VITE_USE_DARK_MODE
export const VITE_AEM_USE_FALLBACK_WITH_EN = import.meta.env
	.VITE_AEM_USE_FALLBACK_WITH_EN
export const VITE_CP_LEGACY_REMOTE = import.meta.env.VITE_CP_LEGACY_REMOTE
export const BASE_LEGACY_URL = `${VITE_CP_LEGACY_REMOTE}/remoteCPAccess.do`
export const BASE_LEGACY_URL_LOGOUT = `${VITE_CP_LEGACY_REMOTE}/logout.do`
export const CREATE_ACCOUNT_LEGACY_URL = `${VITE_CP_LEGACY_REMOTE}/public/registration/choose.do`
export const FORGOT_PASSWORD_LEGACY_URL = `${VITE_CP_LEGACY_REMOTE}/forgotAuthAction.do`
export const CREATE_ACCOUNT_LEGACY_APP_ID = "createAccount"
export const FORGOT_PASSWORD_LEGACY_APP_ID = "forgotPassword"
export const UI_BASE_URL = import.meta.env.BASE_URL
export const VITE_SHIPBOARD_URL = import.meta.env.VITE_SHIPBOARD_URL
export const VITE_SHIPBOARD_MAP_URL = import.meta.env.VITE_SHIPBOARD_MAP_URL
export const VITE_EQUOTE_URL = import.meta.env.VITE_EQUOTE_URL
export const VITE_AUTH_ENCRYPTION_KEY = import.meta.env.VITE_AUTH_ENCRYPTION_KEY
export const ESPRESSO_TOOL_CODE = "CRUISE-MATCH"

import SunnyCloudyIcon from "@assets/svg/navigation/weather.svg"

export const Brands = {
	ROYAL: "R",
	CELEBRITY: "C",
	SILVERSEA: "V"
} as const

export enum BRANDS {
	ROYAL = "royal",
	CELEBRITY = "celebrity",
	SILVERSEA = "silversea"
}

export enum BRANDS_NAMES {
	ROYAL = "Royal Caribbean",
	CELEBRITY = "Celebrity Cruises",
	SILVERSEA = "Silversea"
}

export const BrandsValues = {
	R: "R",
	C: "C",
	V: "V"
} as const

export type Brand = (typeof Brands)[keyof typeof Brands] | null

export const APP_ID_PARAM = "p"
export const TOKEN_PARAM = "z"
export const SSO_PARAM = "sso"
export const allObject = { value: "all", label: "All" }

export const BRANDED_TOOLCODES = {
	HOTELS: {
		code: "HOTELS",
		availableBrands: [Brands.CELEBRITY, Brands.ROYAL]
	},
	"CRUISE-PLANNER": {
		code: "CRUISE-PLANNER",
		availableBrands: [Brands.CELEBRITY, Brands.ROYAL]
	},
	"DIGITAL-BROCHURES": {
		code: "DIGITAL-BROCHURES",
		availableBrands: [Brands.CELEBRITY, Brands.ROYAL, Brands.SILVERSEA]
	},
	"IMAGE-LIBRARY": {
		code: "IMAGE-LIBRARY",
		availableBrands: [Brands.CELEBRITY, Brands.ROYAL]
	}
}

// Error codes
export const INVALID_USERNAME_ERROR_CODE = "unsuccessfulLoginMessage"
export const INVALID_CREDENTIALS_ERROR_CODE = "attemptsRemaining"
export const ACCOUNT_LOCKED_ERROR_CODE = "accountLockedMessage"

export const MODIFY_AGENT_ACCESS_BTNLABEL = "Modify Agent Access"
export enum Role {
	RCCL = "RCCL",
	RCCLADMIN = "RCCLADMIN",
	ADMIN = "ADMIN"
}

export const USA_CAN_ONLY = ["USA", "CAN"]

export const ALL_VALUE = "all"
export const PENDING = "pending"

// Promotion types
export const PROMO_TYPE_RESIDENT = "RE"
export const PROMO_TYPE_SENIOR_RESIDENT = "RS"
export const PROMO_TYPE_RESIDENT2 = "R2"
export const PROMO_TYPE_SENIOR = "SR"
export const PROMO_TYPE_SENIOR2 = "S2"
export const PROMO_TYPE_FIREFIGHTERS = "FI"
export const PROMO_TYPE_FIREFIGHTERS2 = "F1"
export const PROMO_TYPE_POLICE = "PO"
export const PROMO_TYPE_POLICE2 = "PL"
export const PROMO_TYPE_MILITARY = "MI"
export const PROMO_TYPE_MILITARY2 = "M1"
export const PROMO_TYPE_COLLECTIVOS = "CV"
export const PROMO_TYPE_CIVIL_SERVANT = "CS"

// Legacy Qualifiers
export const SENIOR_QUALIFIER = "S"
export const FIREFIGHTER_QUALIFIER = "F"
export const MILITARY_QUALIFIER = "M"
export const POLICE_QUALIFIER = "P"

export const CABIN_CLASS = {
	INTERIOR: "INTERIOR",
	OUTSIDE: "OUTSIDE",
	BALCONY: "BALCONY",
	DELUXE: "DELUXE"
} as const

export enum REFUNDABILITY_CODE {
	FULL_AMOUNT = "F",
	DEPOSIT_AMOUNT = "D", //Non refundable
	REFUNDABLE = "R"
}

export const REFUNDABILITY_TYPE = {
	N: "Non-Refundable",
	R: "Refundable",
	B: "Both"
} as const

// Idle timeout constants
export const IDLE_TIMEOUT_SECONDS = 1800000 // 30 minutes in milliseconds (30 * 60 * 1000)

export const minValueNumberInput = 0.1
export const maxValueNumberInput = 9999
export const WEATHER_DETAILS = {
	icon: SunnyCloudyIcon,
	description: "-",
	temperature: "-",
	wind: "Wind: - / Hum: -"
}

// PI filters constants
export enum DayRange {
	NEXT_10_DAYS = "NEXT_10_DAYS",
	DAYS_11_TO_30 = "DAYS_11_TO_30",
	DAYS_31_TO_60 = "DAYS_31_TO_60",
	DAYS_61_TO_90 = "DAYS_61_TO_90",
	MORE_THAN_90_DAYS = "MORE_THAN_90_DAYS",
	ALL = "ALL",
	REDEEM_WITHIN_120_DAYS = "REDEEM_WITHIN_120_DAYS",
	EXPIRED_WITHIN_120_DAYS = "EXPIRED_WITHIN_120_DAYS",
	INACTIVE_WITHIN_120_DAYS = "INACTIVE_WITHIN_120_DAYS",
	DAYS_10_TO_30 = "DAYS_10_TO_30",
	DAYS_30_TO_60 = "DAYS_30_TO_60",
	DAYS_60_TO_90 = "DAYS_60_TO_90",
	DAYS_90_TO_120 = "DAYS_90_TO_120",
	MORE_THAN_120_DAYS = "MORE_THAN_120_DAYS"
}

export enum ReservationType {
	ALL = "ALL",
	INDIVIDUAL = "INDIVIDUAL",
	GROUP = "GROUP",
	OPEN = "OPEN",
	CONFIRMED = "CONFIRMED"
}

export enum ScheduledPaymentStatus {
	ALL = "ALL",
	ENROLLED = "Enrolled",
	ELIGIBLE = "Eligible",
	NOT_ELIGIBLE = "Not Eligible"
}

export enum IssueType {
	FINAL_PAYMENT_DUE = "FINAL_PAYMENT_DUE",
	BALANCE_DUE = "BALANCE_DUE",
	EXPIRING_DEPOSIT = "EXPIRING_DEPOSIT",
	EXPIRING_OFFER = "EXPIRING_OFFER",
	EXPIRING_48_HOURS = "EXPIRING_48_HOURS",
	FCC = "FCC",
	CREDIT_EXPIRING_SOON = "CREDIT_EXPIRING_SOON",
	CHECKING_REQUIRED = "CHECKING_REQUIRED",
	DINING_CONFLICT = "DINING_CONFLICT",
	ALL_OTHER = "ALL_OTHER"
}

export enum IssueTypeMapper {
	FINAL_PAYMENT_DUE = "finalPaymentDue",
	BALANCE_DUE = "balanceDue",
	EXPIRING_DEPOSIT = "depositExpiring",
	EXPIRING_OFFER = "nextCruiseExpiringOffer",
	EXPIRING_48_HOURS = "expiringIn48Hours",
	FCC = "fccSailBy",
	CREDIT_EXPIRING_SOON = "creditExpiringSoon",
	CHECKING_REQUIRED = "checkInRequired",
	DINING_CONFLICT = "diningConflict",
	ALL_OTHER = "ALL_OTHER"
}

export enum IssueTypeResult {
	CHECK_IN_REQUIRED = "checkInRequired",
	CREDIT_EXPIRING_SOON = "creditExpiringSoon",
	DESCRIPTION_CHANGE_DINING = "changeDiningTime",
	DESCRIPTION_CHECK_IN_BY = "checkInBy",
	DESCRIPTION_DUE = "due",
	DESCRIPTION_EXPIRES = "expires",
	DESCRIPTION_ON = "on",
	DINING_CONFLICT = "diningConflict",
	EXPIRING_IN_48_HOURS = "expiringIn48Hours",
	EXPIRING_NEXT_CRUISE_DEPOSIT = "expiringNextCruiseDeposit",
	FCC_SAIL_BY = "fccSailBy",
	ICON_TOOLTIP = "viewInAlertCenter",
	NEXT_CRUISE_EXPIRING_OFFER = "nextCruiseExpiringOffer"
}

export const APP_ID = {
	VERB: "VRB",
	EQUOTE: "EQUOTE",
	HYBRIS: "HYBRIS"
}
