import {
	ActionIcon,
	Button,
	Container,
	Image,
	Input,
	MantineThemeOverride,
	Paper,
	PasswordInput,
	Pill,
	RingProgress,
	Select,
	Text,
	TextInput,
	Title,
	UnstyledButton,
	createTheme,
	rem
} from "@mantine/core"
import { DateInput } from "@mantine/dates"

import { IconCaretDownFilled } from "@tabler/icons-react"
import actionIconStyles from "./ActionIcon.module.css"
import buttonStyles from "./Button.module.css"
import checkBoxkStyle from "./Checkbox.module.css"
import dateInputStyles from "./DateInput.module.css"
import imageStyles from "./Image.module.css"
import inputStyles from "./Input.module.css"
import passwordInputStyles from "./PasswordInput.module.css"
import pillStyles from "./Pill.module.css"
import selectStyles from "./Select.module.css"
import textStyles from "./Text.module.css"
import titleStyles from "./Title.module.css"
import unstyledButtonStyles from "./UnstyledButton.module.css"
import focusStyles from "./focus.module.css"

const CONTAINER_SIZES: Record<string, string> = {
	xxs: rem(300),
	xs: rem(400),
	sm: rem(500),
	md: rem(800),
	lg: rem(1256),
	xl: rem(1440),
	xxl: rem(1920)
}

export const theme: MantineThemeOverride = createTheme({
	colors: {
		royal: [
			"#021E3E", // 0
			"#ACE3F7", // 1
			"#2F6BB3", // 2
			"#E87436", // 3
			"#3E474A", // 4
			"#051557", // 5
			"#FFFFFF", // 6
			"#B4CFFA", // 7
			"#004ECB", // 8
			"#052396", // 9
			"#F0F5F9", // 10
			"#173E8D", // 11
			"#CBD7FF", // 12
			"#A8C8CF", // 13
			"#1E2746", // 14
			"#11162A", // 15
			"#181F39", // 16
			"#263053", // 17
			"#77FFFF", // 18
			"#F5F5F5", // 19
			"#D1D2D5", // 20
			"#4F4F4F", // 21
			"#E9F4FF", // 22
			"#3F4B7F", // 23
			"#E0E0E0", // 24
			"#EBEEF7", // 25
			"#4F4F4F", // 26
			"#828282", // 27
			"#11162B", // 28
			"#E7E7E7", // 29
			"#0273BA", // 30
			"#E8E8E8", // 31
			"#FFC7C7", // 32
			"#172FBB", // 33
			"#D4DAF7", // 34
			"#ECEFFE", // 35
			"#64B5F6", // 36
			"#1E88E5", // 37
			"#1565C0", // 38
			"#124E84", // 39
			"#C9DAF6", // 40
			"#B4B9CD", // 41
			"#EEEEEE", // 42
			"#BDBDBD", // 43
			"#424242" // 44
		],
		translucentGray: [
			"rgba(248, 249, 250, 0.5)",
			"rgba(241, 243, 245, 0.5)",
			"rgba(233, 236, 239, 0.5)",
			"rgba(222, 226, 230, 0.5)",
			"rgba(206, 212, 218, 0.5)",
			"rgba(173, 181, 189, 0.5)",
			"rgba(134, 142, 150, 0.5)",
			"rgba(73, 80, 87, 0.5)",
			"rgba(52, 58, 64, 0.5)",
			"rgba(33, 37, 41, 0.5)",
			"rgba(1, 30, 62, 0.23)"
		]
	},
	primaryColor: "royal",
	primaryShade: 9,
	fontFamily: "Poppins, sans-serif",
	headings: {
		fontFamily: "Poppins, sans-serif",
		fontWeight: "600",

		// Individual heading properties
		sizes: {
			h3: {
				fontSize: rem(40)
			},
			h4: {
				fontSize: rem(36)
			},
			h5: {
				fontSize: rem(24)
			},
			h6: {
				fontSize: rem(16)
			}
		}
	},
	radius: {
		infoCard: "calc(4.625rem * var(--mantine-scale))",
		larger: "calc(1.5rem * var(--mantine-scale))"
	},
	components: {
		Button: Button.extend({
			classNames: buttonStyles,
			defaultProps: {
				radius: "xl"
			}
		}),
		Image: Image.extend({
			classNames: {
				root: imageStyles.root
			}
		}),
		Input: Input.extend({
			classNames: inputStyles
		}),
		TextInput: TextInput.extend({
			classNames: inputStyles
		}),
		Paper: Paper.extend({
			defaultProps: {
				radius: "larger"
			}
		}),
		RingProgress: RingProgress.extend({
			defaultProps: {
				roundCaps: true,
				rootColor: "white"
			}
		}),
		Select: Select.extend({
			classNames: selectStyles,
			defaultProps: {
				rightSection: <IconCaretDownFilled fill="var(--text-color-primary)" />,
				withCheckIcon: false,
				allowDeselect: false
			}
		}),
		Title: Title.extend({
			classNames: {
				root: titleStyles.root
			},
			styles: {
				root: {
					padding: 0
				}
			}
		}),
		Text: Text.extend({
			classNames: textStyles,
			styles: {
				root: {
					maxWidth: 919
				}
			}
		}),
		DateInput: DateInput.extend({
			classNames: dateInputStyles
		}),
		PasswordInput: PasswordInput.extend({
			classNames: passwordInputStyles
		}),
		Pill: Pill.extend({
			classNames: pillStyles
		}),
		UnstyledButton: UnstyledButton.extend({
			classNames: unstyledButtonStyles
		}),
		ActionIcon: ActionIcon.extend({
			classNames: actionIconStyles
		}),
		Container: Container.extend({
			vars: (_, { size, fluid }) => ({
				root: {
					"--container-size": fluid
						? "100%"
						: size !== undefined && size in CONTAINER_SIZES
							? CONTAINER_SIZES[size]
							: rem(size)
				}
			})
		}),
		Checkbox: ActionIcon.extend({
			classNames: checkBoxkStyle
		})
	},
	spacing: {
		xs: "0.625rem",
		sm: "0.75rem",
		md: "1rem",
		lg: "1.25rem",
		xl: "1.5rem"
	},
	focusClassName: focusStyles.focus,
	other: {
		gradients: {
			headline: "linear-gradient(90deg, #052396 0%, #39BDD0 100%)",
			"title-background":
				"linear-gradient(0deg, #051557 0%, #223BA2 100%), #051557",
			background:
				"linear-gradient(90deg, #173E8D 0%, #2352A3 50%, #032B71 100%)"
		}
	}
})
